.content-login {
  height: 100vh;
  width: 100vw;
  padding: 0;

  @media (max-width: $minWidthContent) {
    min-width: auto;
  }
}

.auth-section {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;

  h2 {
    width: 100%;
    font-weight: normal;
    color: rgba($dark, .7);
    display: block;
    text-align: center;
    font-size: 32px;

    strong {
      color: rgba($dark, .9);
    }
  }

  @media (max-width: $minWidthContent) {
    //background: rgba($white, .6);
    float: left;
    width: 100%;
    padding: 80px 0 0 0;
    justify-content: flex-start;
  }

  @media (max-width: 620px) {
    h2 {
      font-size: 24px;
    }
  }
}


.login-view {

  .loading-overlay {
    left: 50% !important;
  }
}

.auth-message {
  font-size: 15px;
}

.login-body {
  width: 100%;
  padding: 20px;
  margin: 50px 0 50px 0;
  max-width: 420px;

  label {
    font-size: 17px;
  }

  p {
    font-size: 15px;
    color: $gray;
    margin: 0 0 60px 0;
    text-align: center;
  }

  a {
    color: $gray;
    float: right;
    font-size: 14px;
    margin: 8px 0 0 0;

    &:hover {
      text-decoration: underline;
    }

    &:active {
      text-decoration: none;
    }
  }

}

.login-footer {
  display: flex;
  justify-content: space-between;
  margin: 50px 0 0 0;

  @media (max-width: 620px) {
    margin: 20px 0 0 0;
    flex-direction: column;
    gap: 20px;
  }

  button {
    padding: 5px 25px !important;
    text-align: right;
  }
}

.blob-1 {
  background: url(/assets/img/blob-1.svg) no-repeat;
  position: absolute;
  height: 623px;
  width: 676px;
  top: 0;
  left: 0;
  z-index: -1;

  @media (max-width: $minWidthContent) {
    top: -200px;
    left: -200px;
  }

  @media (max-width: 620px) {
    top: -280px;
    left: -280px;
  }
}


.blob-2 {
  background: url(/assets/img/blob-2.svg) no-repeat;
  position: absolute;
  height: 518px;
  width: 511px;
  bottom: 0;
  right: 0;
  z-index: -1;

  @media (max-width: $minWidthContent) {
    bottom: -100px;
    right: -100px;
  }

  @media (max-width: 620px) {
    bottom: -200px;
    right: -200px;
  }
}
