.p-overlaypanel {
  border-radius: 0 !important;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.08) !important;

  table {
    width: 100%;
    table-layout: fixed;
    font-size: 13px;
    border-collapse: collapse;

    th {
      text-align: left;
    }
  }

  tr {
    &:hover {
      background: $hoverLight;
    }
  }

  &.with-border {
    border: 2px solid $main1;
    box-shadow: none !important;
  }
}

.p-overlaypanel .p-overlaypanel-close {
  top: 0;
  right: 0;
}

.p-toast-icon-close-icon {
  color: $white;
}

.p-overlaypanel .p-overlaypanel-close {
  box-shadow: none !important;
  border-radius: 0 !important;
  background: none;
  color: $gray;

  &:hover {
    color: $dark !important;
    background: $hoverLight !important;
  }

  &:active {
    background: transparent !important;
  }
}

.table-name-headline {
  width: 220px;
}

.p-overlaypanel::after, .p-overlaypanel::before {
  display: none;
}

.header-buttons {
  .p-overlaypanel {
    top: 28px !important;
    max-width: 560px !important;
    width: 100% !important;
    max-height: 500px;
    overflow-y: auto;

    .local-system & {
      top: calc(28px + #{$headerBannerHeight}) !important;
    }
  }

  .p-overlaypanel-content {
    padding: 0 !important;
  }
}

.todo-header-title {
  background: $hoverLight;
  font-size: 12px;
  text-transform: uppercase;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0;

  &.with-action {
    padding: 0 0 0 10px;
  }

  .pi {
    cursor: pointer;
    padding: 5px 10px;
    color: $gray;

    &:hover {
      background: darken($hoverLight, 10%);
      color: $dark;
    }

    &:active {
      opacity: .6;
    }
  }
}

.todo-header-container {
  display: flex;
}

.todo-header-action {
  display: flex;
  padding: 0 10px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $lightGray;
  color: $gray;

  &:hover {
    background: $hoverLight;
    color: $dark;
  }

  &:active {
    background: darken($hoverLight, 5%) !important;
  }
}

.todo-header {
  padding: 5px 10px;
  border-bottom: 1px solid $lightGray;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  user-select: none;
  width: 100%;

  &:hover {
    background: $hoverLight;
  }

  &:active {
    background: darken($hoverLight, 5%) !important;
  }

  span {
    color: $gray;
    font-size: 13px;
  }

  strong {
    font-size: 13px;
  }

  &:last-child {
    border: none;
  }
}

.dark-overlay {
  background: $dark !important;
  box-shadow: none !important;
  color: $white !important;
  font-size: 14px !important;
  max-width: 280px !important;
}
