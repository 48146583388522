@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("../fonts/OpenSans-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url("../fonts/OpenSans-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  src: url("../fonts/OpenSans-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url("../fonts/OpenSans-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "The-Icon-of";
  src: url("../fonts/The-Icon-of.eot?2kauo3");
  src: url("../fonts/The-Icon-of.eot?2kauo3#iefix") format("embedded-opentype"),
    url("../fonts/The-Icon-of.ttf?2kauo3") format("truetype"),
    url("../fonts/The-Icon-of.woff?2kauo3") format("woff"),
    url("../fonts/The-Icon-of.svg?2kauo3#The-Icon-of") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="tio-"],
[class*=" tio-"],
[class^="tio"],
[class*=" tio"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "The-Icon-of" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  user-select: none;

  /* Enable Ligatures ================ */
  letter-spacing: 0;
  -webkit-font-feature-settings: "liga";
  -moz-font-feature-settings: "liga=1";
  -moz-font-feature-settings: "liga";
  -ms-font-feature-settings: "liga" 1;
  font-feature-settings: "liga";
  -webkit-font-variant-ligatures: discretionary-ligatures;
  font-variant-ligatures: discretionary-ligatures;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
