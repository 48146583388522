.p-multiselect {
  border-radius: 0 !important;
  border: 1px solid $lightGray;

  .p-invalid & {
    border: 1px solid $alert;
  }
}

.p-multiselect-panel {
  border-radius: 0 !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08) !important;
  border: 1px solid $background !important;
}

.p-multiselect-close {
  box-shadow: none !important;

  .content-header-buttons & {
    display: none !important;
  }
}

.p-multiselect .p-multiselect-label {
  padding: 5px 10px !important;
  font-size: 15px !important;
}

.p-multiselect:not(.p-disabled):hover {
  border: 1px solid $main2 !important;
  box-shadow: 0 0 3px 0 $main2 !important;
}

.p-multiselect:not(.p-disabled).p-focus {
  border: 1px solid $main1 !important;
  box-shadow: 0 0 3px 0 $main1 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  box-shadow: none !important;
  padding: 5px 1rem !important;
  font-size: 15px !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: $background !important;
  box-shadow: none !important;
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0 !important;
}

.p-multiselect-filter {
  height: 25px !important;
}
