.p-inputtext {
  padding: 5px 10px;
  font-size: 15px;
  border: 1px solid $lightGray;
  border-radius: 0 !important;
  color: $main1;

  .auth-section & {
    padding: 8px 10px;
  }

  .p-invalid & {
    border: 1px solid $alert;
  }

  &:not(.p-dropdown-label):hover {
    border: 1px solid $main2 !important;
    box-shadow: 0 0 3px 0 $main2 !important;
  }

  &:not(.p-dropdown-label):focus,
  &:not(.p-dropdown-label):active {
    border: 1px solid $main1 !important;
    box-shadow: 0 0 3px 0 $main1 !important;
  }
}

.searchfield-active {
  input {
    background: $main2;
    color: $white;
  }

  .pi-search {
    color: $white !important;
  }
}

.has-small-input {
  display: flex;
  align-items: center;
  gap: 10px;
  white-space: nowrap;

  .p-inputtext {
    padding: 0 5px !important;
  }

  .p-dropdown .p-inputtext {
    width: 80px !important;
  }
}

.p-column-filter-row {
  height: 25px !important;
}

.p-column-filter .p-inputtext {
  height: 25px !important;
}

.p-column-filter-overlay {
  border-radius: 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid $background;
}

.p-column-filter-overlay .p-column-filter-row-items {
  padding: 0;
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-row-item {
  padding: 5px 12px;
  box-shadow: none !important;
  font-size: 14px;

  &:active {
    background: $background !important;
  }
}

.p-column-filter-overlay .p-column-filter-row-items .p-column-filter-separator {
  margin: 0;
}

.p-column-filter-overlay
  .p-column-filter-row-items
  .p-column-filter-row-item.p-highlight {
  background: $background !important;
}

.p-dropdown-panel {
  border-radius: 0;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08);
  border: 1px solid $background;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
  font-size: 14px;
  color: $dark;
  padding: 3px 10px;

  &:active {
    background: $background !important;
  }
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
  background: $background;
}

.p-dropdown-panel .p-dropdown-items {
  padding: 0 !important;
}

.p-dropdown-header {
  .p-dropdown-filter {
    height: 25px !important;
  }
}

.p-dropdown {
  border-radius: 0;
  border: 1px solid $lightGray;

  .p-inputtext {
    border: none !important;
  }
}

.required-symbol {
  color: $alert;
  font-size: 16px;
  font-weight: bold;
  font-style: normal;
  margin: 0 0 0 5px;
}

.p-inputgroup > p-dropdown + .p-inputgroup-addon,
.p-inputgroup > p-calendar + .p-inputgroup-addon,
.p-inputgroup > p-inputnumber + .p-inputgroup-addon {
  border-left: 0 none !important;
}

p-dropdown.p-invalid > .p-dropdown {
  border: 1px solid $alert;
}

.p-dropdown:not(.p-disabled):hover {
  border: 1px solid $main2 !important;
  box-shadow: 0 0 3px 0 $main2 !important;
}

.p-dropdown:not(.p-disabled).p-focus {
  border: 1px solid $main1 !important;
  box-shadow: 0 0 3px 0 $main1 !important;
}

.p-field {
  //margin: 0;
  //padding: 10px 0;

  strong {
    font-weight: 600;
  }

  .p-password {
    width: 100%;
  }

  input {
    width: 100% !important;

    &::placeholder {
      color: $dark !important;
    }
  }

  > label {
    //margin-bottom: 0;
    color: $dark;
    font-size: 14px;
    display: flex;
    align-items: center;
    min-height: 20px;

    .pi-info-circle {
      margin: 0 0 0 10px;
    }
  }

  .smaller-grid & {
    margin: 0 0 5px 0 !important;
  }
}

.input-height-light {
  height: 25px !important;

  .p-button-icon {
    font-size: 12px !important;
  }

  .p-inputnumber-button {
    padding: 0 !important;
    background: $lightGray !important;
    color: $gray !important;
    box-shadow: none !important;

    &:hover {
      background: darken($lightGray, 10%) !important;
    }
  }
}

.file-input-special {
  padding: 20px;
  border: 2px dashed $lightGray !important;

  &.p-inputtext:not(.p-dropdown-label):hover,
  &.p-inputtext:not(.p-dropdown-label):focus,
  &.p-inputtext:not(.p-dropdown-label):active {
    box-shadow: none !important;
    border: 2px dashed $lightHighlightDarker !important;
  }

  &:hover {
    border: 2px dashed $lightHighlightDarker !important;
  }

  &:active {
    border: 2px dashed $lightGray !important;
  }
}

.p-formgrid {
  border-bottom: 1px solid $background;
  padding: 15px 0 0 0;

  &:first-child {
    padding: 0;
  }

  &.without-border {
    border: none;
  }

  &.border-top {
    border-top: 1px solid $background;
  }

  &:last-child {
    border: none;

    .p-field {
      // margin: 0;
    }
  }

  .smaller-grid & {
    padding: 10px 0 0 0;
  }
}

.p-formgroup-inline {
  position: relative;

  &.has-form-height {
    min-height: 32px;
  }

  .p-d-flex & {
    margin: 0 0 0 10px;
  }
}

.p-field-checkbox,
.p-field-radiobutton {
  font-size: 14px;
  color: $dark;
  margin-bottom: 5px !important;

  &.full-width {
    width: 100%;
  }

  .pi-info-circle {
    margin: 0 0 0 10px;
  }
}

.checkbox-with-calendar {
  justify-content: space-between;
}

.p-checkbox .p-checkbox-box {
  border-radius: 0;
}

.p-float-label > label {
  font-size: 13px;
  line-height: normal;
}

.align-bottom {
  display: flex;
  align-items: flex-end;
  padding-bottom: 5px !important;

  .p-field-checkbox {
    margin-bottom: 0 !important;
  }
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  border-color: $main1;
  background: $main1;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: $main2;
}

.p-radiobutton .p-radiobutton-box.p-highlight:not(.p-disabled):hover {
  border-color: $main1;
  background: $main1;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  border-color: $main1;
  box-shadow: 0 0 0 0.2rem rgba($main2, 0.5);
}

.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $main1;
  background: $main1;
}

p-tristatecheckbox .p-checkbox-box.p-highlight:has(.pi-times) {
  border-color: $alert !important;
  background: $alert !important;
}

p-tristatecheckbox
  .p-checkbox:not(.p-checkbox-disabled)
  .p-checkbox-box.p-focus:has(.pi-times) {
  box-shadow: 0 0 0 0.2rem rgba($alert, 0.5);
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover {
  border-color: $main2;
}

[ng-reflect-readonly="true"] .p-checkbox-box:hover {
  border-color: #ced4da !important;
  cursor: default !important;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: $main1;
  background: $main1;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  border-color: $main1;
  box-shadow: 0 0 0 0.2rem rgba($main2, 0.5);
}

.p-field-checkbox > label,
.p-field-radiobutton > label,
.p-radiobutton-label,
.p-checkbox-label {
  margin-left: 0 !important;
  padding-left: 0.5rem !important;
}

.please-describe-label {
  &::after {
    content: "(Bitte erläutern)";
    font-size: 13px;
    margin: 0 0 0 5px;
    color: $gray;
  }
}

.p-error,
.p-invalid {
  color: $alert !important;
}

.p-inputtext.p-error,
.p-inputtext.p-invalid,
.p-inputtext.ng-dirty.ng-invalid {
  border-color: $alert;
}

.p-inputgroup-addon:first-child,
.p-inputgroup button:first-child,
.p-inputgroup input:first-child {
  border-radius: 0 !important;
}

.inputgroup-addon-day {
  width: 50px;
}

.p-inputgroup-addon {
  padding: 0;
  background: transparent;

  .dialog-sidebar & {
    color: $white;
  }

  .white-sidebar & {
    color: $dark;
  }

  &:last-child {
    border-radius: 0 !important;
  }
}

.inputgroup-addon-clickable {
  cursor: pointer;

  &:hover {
    background: $hoverLight;
  }

  &:active {
    background: darken($hoverLight, 5%);
  }

  &.active {
    background: $main1;
    color: $white;

    &:hover {
      background: lighten($main1, 5%);
    }

    &:active {
      background: darken($main1, 5%);
    }
  }
}

.full-height-file-input {
  height: calc(100% - 40px);
}

.big-file-input {
  padding: 50px 20px;
  color: $white;
  border: 2px dashed $white !important;
  background: transparent;
  height: 100%;

  &.p-inputtext:not(.p-dropdown-label):hover {
    border: 2px dashed $white !important;
    background: rgba($white, 20%) !important;
    box-shadow: none !important;
  }

  &.p-inputtext:not(.p-dropdown-label):active,
  &.p-inputtext:not(.p-dropdown-label):focus {
    border: 2px dashed $white !important;
    background: transparent !important;
    box-shadow: none !important;
  }
}
