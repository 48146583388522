.checks-container {

  p-skeleton {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .p-skeleton {
    background: $main2 !important;

    &:after {
      background: $main1 !important;
    }
  }
}
