.p-tree {
  border-radius: 0 !important;
  border: 1px solid $lightGray;
  padding: 0 !important;
}

.p-tree .p-tree-container .p-treenode {
  padding: 0 !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content.p-highlight {
  background: $background !important;
}

.p-tree .p-tree-container .p-treenode .p-treenode-content {
  border-radius: 0 !important;
  box-shadow: none !important;

  &:hover {
    background: $hoverLight !important;
  }

  &:active {
    background: $background !important;
  }
}

.p-treeselect {
  border-radius: 0 !important;
  border: 1px solid $lightGray;

  .p-invalid & {
    border: 1px solid $alert;
  }

  .proofs-treeselect & {
    width: 180px;
  }
}

.p-treeselect .p-treeselect-label.p-placeholder {
  color: #6c757d !important;
}

.p-multiselect .p-multiselect-label.p-placeholder {
  color: #6c757d !important;
}

.p-multiselect-panel {
  border-radius: 0 !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08) !important;
  border: 1px solid $background !important;
}

.p-treeselect-panel {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08) !important;
  border-radius: 0 !important;
  border: 1px solid $background;
}

.p-treeselect-panel .p-treeselect-header {
  padding: 3px 10px !important;
}

.p-treeselect-panel
  .p-treeselect-header
  .p-treeselect-filter-container
  .p-treeselect-filter {
  height: 25px !important;
}

.p-multiselect-close {
  box-shadow: none !important;

  .content-header-buttons & {
    display: none !important;
  }
}

.p-treenode-label {
  font-size: 14px !important;
}

.p-treeselect .p-treeselect-panel {
  min-width: 400px !important;
  width: 100% !important;
}

.p-tree
  .p-tree-container
  .p-treenode
  .p-treenode-content
  .p-tree-toggler:enabled:hover {
  background: transparent !important;
  border: none !important;
}

.p-treeselect .p-treeselect-label {
  padding: 5px 10px !important;
  font-size: 15px !important;
  color: $main1 !important;
}

.p-treeselect:not(.p-disabled):hover {
  border: 1px solid $main2 !important;
  box-shadow: 0 0 3px 0 $main2 !important;
}

.p-treeselect:not(.p-disabled).p-focus {
  border: 1px solid $main1 !important;
  box-shadow: 0 0 3px 0 $main1 !important;
}

.p-multiselect-label {
  color: $main1 !important;
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  box-shadow: none !important;
  padding: 5px 1rem !important;
  font-size: 15px !important;

  &:hover {
    background: $hoverLight !important;
  }

  &:active {
    background: $background !important;
  }
}

.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  background: $background !important;
  box-shadow: none !important;
}

.p-multiselect-panel .p-multiselect-items {
  padding: 0 !important;
}

.p-multiselect-filter {
  height: 25px !important;
}
