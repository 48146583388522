.p-confirm-popup-message {
  &:first-of-type {
    margin: 0 !important;
  }
}

.p-confirm-popup {
  border-radius: 0 !important;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.08) !important;
}
