.p-datepicker {
  padding: 0 !important;
}

.p-datepicker table th {
  padding: 0 !important;
}

.p-datepicker table td {
  padding: 0 !important;
}

.p-datepicker .p-timepicker button {
  width: 20px !important;
  height: 20px !important;
}

.p-datepicker .p-timepicker span {
  font-size: 14px !important;
}

.p-datepicker table td > span {
  height: 32px !important;
  width: 32px !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  > .p-element {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }
}

.p-datepicker .p-datepicker-header {
  padding: 2px !important;
}

.datepicker-as-icon {
  .p-inputtext {
    display: none;
  }

  .p-button {
    background: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: $dark !important;

    &:active {
      opacity: 0.5;
    }
  }

  .p-datepicker {
    top: 30px !important;
  }
}

.p-link {
  box-shadow: none !important;
}

.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-year:enabled:hover,
.p-datepicker
  .p-datepicker-header
  .p-datepicker-title
  .p-datepicker-month:enabled:hover {
  color: $main2;
}

.p-datepicker .p-monthpicker .p-monthpicker-month {
  border-radius: 0;
}

.p-datepicker .p-yearpicker .p-yearpicker-year {
  border-radius: 0;
}

.p-datepicker .p-yearpicker .p-yearpicker-year.p-highlight {
  background: $hoverLight;
}

.p-datepicker:not(.p-disabled)
  .p-yearpicker
  .p-yearpicker-year:not(.p-disabled):focus {
  box-shadow: none !important;
}

.p-datepicker:not(.p-disabled)
  .p-monthpicker
  .p-monthpicker-month:not(.p-disabled):focus {
  box-shadow: none !important;
}

.p-datepicker table td.p-datepicker-today > span {
  background: $hoverLight;
}

.p-datepicker:not(.p-disabled)
  table
  td
  span:not(.p-highlight):not(.p-disabled):active {
  background: $background;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
  background: $main2;
  color: $white;
}

.p-datepicker table td > span.p-highlight {
  background: $main2;
  color: $white;
  font-weight: 600;
}

.p-datepicker-buttonbar {
  padding: 0 !important;

  // Versteckt den "Clear" Button.
  button {
    &:last-child {
      display: none;
    }
  }
}

.p-datepicker .p-datepicker-header .p-datepicker-prev,
.p-datepicker .p-datepicker-header .p-datepicker-next {
  border-radius: 0 !important;
  box-shadow: none !important;
}
