.fc-col-header {
  width: 100% !important;
}

.fc-timegrid-body {
  width: 100% !important;

  table {
    width: 100% !important;
  }

  .fc-event-time {
    display: none !important;
  }
}

.fc-event-title {
  white-space: pre-wrap;
}

.fc-timegrid-event,
.fc-timegrid-more-link {
  font-size: 13px !important;
  border-radius: 0 !important;
}
