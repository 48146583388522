@import "variables", "fonts", "primeng/form", "primeng/table", "primeng/autocomplete",
  "primeng/button", "primeng/confirm-popup", "primeng/overlaypanel",
  "primeng/datepicker", "primeng/skeleton", "primeng/multiselect",
  "primeng/tree", "primeng/badge", "primeng/tooltip", "primeng/menu",
  "primeng/dialog", "primeng/toast", "primeng/selectbutton", "primeng/sidebar",
  "primeng/contextmenu", "primeng/fullcalendar", "primeng/slider", "content", "auth",
  "notice";

* {
  box-sizing: border-box;
  font-family: "Open Sans", sans-serif;
  margin: 0;
  padding: 0;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-kerning: auto;
  outline: none;
}

body {
  background: $background;
}

.loading-cursor {
  cursor: progress;
}

html {
  -webkit-text-size-adjust: 100%;
}

.pointer-events-none {
  pointer-events: none;
}

.header-banner {
  height: 100%;
  width: 100%;
  display: flex;
  grid-area: banner;
  background: $alert;
  color: $white;
  text-align: center;
  justify-content: center;
  font-size: 15px;

  &.demo-banner {
    background: $paused;
  }
}

app-root {
  display: grid;
  height: 100vh;
  //transition: grid-template-columns .1s linear 0s;
  grid-template-rows: $headerHeight 1fr;
  grid-template-columns: $navWidth 1fr;
  grid-template-areas:
    "header header"
    "nav content";

  @media (max-width: 1000px) {
    //grid-template-columns: $navWidthSmaller 1fr;
  }

  .nav-hidden & {
    grid-template-columns: $navHiddenWidth 1fr;
  }

  .local-system & {
    grid-template-rows: $headerBannerHeight $headerHeight 1fr;
    grid-template-areas:
      "banner banner"
      "header header"
      "nav content";
  }

  .local-system.secondary-view & {
    grid-template-rows: $headerBannerHeight $headerHeightSecondaryView $navHeight 1fr;
    grid-template-areas:
      "banner"
      "header"
      "nav"
      "content";
  }

  .secondary-view & {
    grid-template-rows: $headerHeightSecondaryView $navHeight 1fr;
    grid-template-columns: 1fr;
    grid-template-areas:
      "header"
      "nav"
      "content";

    @media (max-width: 1000px) {
      .mobile-nav-visible & {
        grid-template-rows: $headerHeightSecondaryView 1fr;
        grid-template-columns: 1fr;
        grid-template-areas:
          "header"
          "content";
      }
    }
  }
}

::selection {
  background: rgba($main1, 0.99);
  color: #fff;
}

a {
  text-decoration: none;
}

.no-select {
  user-select: none;
}

.color-alert {
  color: $alert !important;
}

.color-yellow {
  color: $yellow !important;
}

.color-main1 {
  color: $main1 !important;
}

.background-hightlight-main1 {
  background: rgba($main1, 0.1) !important;

  &:hover {
    background: rgba($main1, 0.2) !important;
  }
}

.background-main1 {
  background: $main1 !important;
}

.color-blue {
  color: $blue !important;
}

.color-white {
  color: $white !important;
}

.background-blue {
  background: $blue !important;

  &:hover {
    background: darken($blue, 10%) !important;
  }
}

.background-white {
  background: $white !important;
}

.background-gray {
  background: $gray !important;

  &:hover {
    background: darken($gray, 10%) !important;
  }
}

.color-gray {
  color: $gray !important;
}

.background-hightlight-gray {
  background: rgba($gray, 0.1) !important;

  &:hover,
  &.highlight {
    background: rgba($gray, 0.2) !important;
  }
}

.background-hightlight-hover {
  background: $hoverLight !important;
}

.color-dark {
  color: $dark !important;
}

.color-orange {
  color: $orange !important;
}

.background-orange {
  background: $orange !important;
}

.background-hightlight-orange {
  background: rgba($orange, 0.1) !important;

  &:hover,
  &.highlight {
    background: rgba($orange, 0.3) !important;
  }
}

.background-hightlight-blue {
  background: rgba($blue, 0.2) !important;

  &:hover,
  &.highlight {
    background: rgba($blue, 0.3) !important;
  }

  td {
    border-bottom: 1px solid $white !important;

    @media (max-width: 960px) {
      border-bottom: 0 !important;

      &:last-child {
        border-bottom: 1px solid $white !important;
      }
    }
  }
}

.background-hightlight-yellow {
  background: rgba($yellow, 0.3) !important;

  &:hover {
    background: rgba($yellow, 0.5) !important;
  }
}

.color-main2 {
  color: $main2 !important;
}

.background-hightlight-main2 {
  background: rgba($main2, 0.25) !important;

  &:hover {
    background: rgba($main2, 0.35) !important;
  }
}

.background-main2 {
  background: $main2 !important;
}

.background-alert {
  background: $alert !important;

  &:hover {
    background: darken($alert, 10%) !important;
  }
}

.background-hightlight-alert {
  background: rgba($alert, 0.1) !important;

  &:hover {
    background: rgba($alert, 0.2) !important;
  }

  &.no-hover {
    &:hover {
      background: rgba($alert, 0.1) !important;
    }
  }

  td {
    border-bottom: 1px solid $white !important;
  }
}

.background-hightlight-alert-no-opacity {
  background: #fdebeb !important;

  &:hover {
    background: #fde1e1 !important;
  }
}

.loading-logo {
  background: url("/assets/img/loading-logo.svg");
  width: 82px;
  height: 82px;
  animation: moveUpDown 0.4s infinite alternate;
}

@keyframes moveUpDown {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.gelatine {
  animation: gelatine 1s infinite;
}

@keyframes gelatine {
  from,
  to {
    transform: scale(1, 1) translateY(-50%);
  }
  25% {
    transform: scale(0.9, 1.1) translateY(-50%);
  }
  50% {
    transform: scale(1.1, 0.9) translateY(-50%);
  }
  75% {
    transform: scale(0.95, 1.05) translateY(-50%);
  }
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

// DRAGULA Styles

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  pointer-events: none;
  box-shadow: 0 0 0 3px $blue;
}
/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}
/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  user-select: none !important;
}
/* added to the source element while its mirror is dragged */
.gu-transit {
  box-shadow: 0 0 0 3px $blue;

  .content-header,
  .content-body {
    opacity: 0.5;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #ffffff inset !important;
}
