.p-contextmenu {
  padding: 0;
  max-width: 300px;
  width: auto;
  box-shadow: 0 5px 10px 0 rgba(0,0,0,.08);
  background: $main2;
  color: $white;

}

.p-contextmenu .p-menuitem.p-menuitem-active > .p-menuitem-link {
  background: lighten($main1, 3%);
}

.p-contextmenu .p-menuitem-link {
  box-shadow: none !important;
  font-size: 14px;
  padding: 10px 15px !important;

  &:active {
    background: $main1 !important;
  }
}

.p-contextmenu .p-menuitem-link .p-menuitem-icon {
  font-size: 13px !important;
  color: $white !important;
}

.p-contextmenu .p-menuitem-text {
  color: $white !important;
}
