.header-menu {
  &.p-menu.p-menu-overlay {
    background: $lightDark;
    box-shadow: none;
    width: 180px;
    padding: 0;
    left: auto !important;
    right: 0;
    border-radius: 0 !important;
  }

  &.p-menu .p-menuitem-link {
    padding: 10px 20px;
    box-shadow: none !important;
    justify-content: flex-end;
  }

  &.p-menu .p-menuitem-link .p-menuitem-text {
    color: $lightGray !important;
    font-size: 14px;
  }

  &.p-menu .p-menuitem-link:not(.p-disabled):hover {
    background: lighten($lightDark, 3%);
  }

  &.p-menu .p-menuitem-link:not(.p-disabled):active {
    background: $dark;
  }
}

.p-tieredmenu {
  width: auto;
}
