.p-slider.p-slider-horizontal {
  height: 20px;
  background: $hoverLight;
  border-radius: 0;
}

.p-slider .p-slider-range {
  background: $gradient;
}

.p-slider .p-slider-handle {
  border-radius: 0;
  height: 20px;
  width: 30px;
  box-shadow: none !important;
  background: $white;
  border: 1px solid $lightHighlight !important;
}

.p-disabled .p-slider-handle {
  display: none !important;
}

.p-slider-horizontal .p-slider-handle {
  top: auto !important;
  margin-top: 0 !important;
  margin-left: -15px !important;

  &:hover {
    background: $white !important;
  }
}
