.p-autocomplete-emptymessage {
  font-size: 15px !important;
  text-align: center;
  cursor: default !important;

  &:hover {
    background: transparent !important;
  }
}

p-autocomplete {
  .name-block & {
    width: 100%;

    .p-autocomplete {
      width: 100%;
    }

    input {
      width: 100%;
    }
  }
}

.p-autocomplete-panel .p-autocomplete-items {
  padding: 0 !important;
}

.p-autocomplete-panel {
  border-radius: 0 !important;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.08) !important;
}

.p-autocomplete .p-autocomplete-loader {
  color: rgba($white, 0.6);
}

app-header {
  .p-autocomplete .p-autocomplete-panel {
    max-height: 500px !important;
    max-width: 100% !important;
  }
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-result-data {
  display: flex;
  flex-direction: column;
}

.search-result-name {
  font-size: 14px;

  .p-tag {
    margin: 0 0 0 20px;
  }
}

.search-result-id {
  font-size: 13px;
  color: $gray;
  margin: 0 0 0 5px;
}

.search-result-type {
  text-align: right;
  font-size: 20px;
  color: $lightHighlight;
}

.search-result-address {
  font-size: 13px;
  color: $gray;
}

.p-autocomplete-panel .p-autocomplete-items .p-autocomplete-item.p-highlight {
  background: $background !important;
}
