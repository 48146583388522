.p-tooltip {
}

.p-tooltip-arrow {
  display: none;
}

.p-tooltip .p-tooltip-text {
  box-shadow: none;
  border-radius: 0;
  padding: 5px 8px;
  background: $lightDark;
  font-size: 14px;
}

.tooltip-wider,
.tooltip-wider-right,
.tooltip-wider-left {
  max-width: unset !important;

  th {
    text-align: left;
    padding: 0 90px 10px 0;
    white-space: nowrap;

    &:last-child {
      padding-right: 0;
    }
  }

  .p-tooltip-text {
    width: max-content;
    max-width: 800px;
  }
}

.tooltip-wider-light {
  .p-tooltip-text {
    max-width: 600px;
  }
}

.tooltip-wider-left {
  display: flex !important;
  justify-content: flex-end !important;
}

.tooltip-wider-right {
  display: flex !important;
  justify-content: flex-start !important;
}
