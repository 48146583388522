$main1: #25b155;
$main2: #84bc3c;
$dark: #2c3239;
$lightDark: #383c42;
//$lightDark: lighten($dark, 30%);
$alert: #eb3d3d;
$paused: #9404d7;
$gray: #7a7c82;
$grayIcon: #5a6470;
//$lightGray: #E2E4EA;
$lightGray: #d2d7d0;
$blue: #3296dc;
$orange: #f59b21;
$yellow: #fffc18;
$lightHighlight: #989ea6;
$lightHighlightDarker: darken(#989ea6, 15%);

$hoverLight: #e9ecef;
$bg: #f3f8fa;
$white: #fff;
$black: #282936;

$gradient: linear-gradient(to right, $main1, $main2);
$issues: linear-gradient(to right, $alert, $orange);
$special: linear-gradient(to right, $blue, #37adff);

$background: #dfe3de;

$contentHeaderHeight: 40px;
$contentHeaderSmallerHeight: 32px;
$headerBannerHeight: 20px;
$headerHeight: 38px;
$headerHeightSecondaryView: 32px;
$navHeight: 50px;
$navWidth: 280px;
$navWidthSmaller: 220px;
$navHiddenWidth: 70px;
$budgetBoxWidth: 520px;
$budgetTypeWidth: 180px;
$minWidthContent: 1200px;
$dialogFooterHeight: 54px;
