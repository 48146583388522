.select-field-light {
  border: none;
  border-bottom: 2px solid $background;
  background: none;
  font-size: 14px;
  color: $dark;
}

.select-field {
  border: 2px solid $background;
  background: none;
  font-size: 14px;
  color: $dark;
  padding: 0 5px;
}

p-selectbutton.p-invalid .p-button {
  border: 2px solid $alert !important;
}

.p-selectbutton {
  display: flex;
  gap: 10px;
}

.p-selectbutton .p-button {
  margin: 0 5px 0 0 !important;

  &:last-child {
    margin: 0 !important;
  }

  &.p-highlight {
    background: $main1;

    &:hover {
      background: $main1;
      box-shadow: 0 0 0 3px rgba($main1, 0.5);
    }
  }
}

.selectbutton-small {
  .p-button {
    padding: 0 !important;
    margin: 0 !important;
    border: none !important;
    color: $white !important;
    background: transparent !important;
    box-shadow: none !important;
    flex-basis: content !important;

    &:not(.p-highlight) {
      &:active {
        opacity: 0.5;
      }

      span {
        background: transparent !important;
        color: $dark !important;
      }

      &:hover span {
        background: $lightGray !important;
      }
    }

    span {
      padding: 4px 15px;
      font-size: 13px;
      border-radius: 5px !important;
    }
  }
}
